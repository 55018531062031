import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><undefined parentName="p">{`
                `}<div {...{
          "className": "center horizontal"
        }}>{`
                  `}<img parentName="div" {...{
            "src": "/static/5b01add7495a65396a07cba240e62fd3/aa440/Morne_he_Dark_Between_the_void_between_realms_vantablack_planet_a58cae09-c2ef-4fa9-acb6-f5d7f8394b30.png",
            "srcSet": ["/static/5b01add7495a65396a07cba240e62fd3/5ff7e/Morne_he_Dark_Between_the_void_between_realms_vantablack_planet_a58cae09-c2ef-4fa9-acb6-f5d7f8394b30.png 375w", "/static/5b01add7495a65396a07cba240e62fd3/1d69c/Morne_he_Dark_Between_the_void_between_realms_vantablack_planet_a58cae09-c2ef-4fa9-acb6-f5d7f8394b30.png 750w", "/static/5b01add7495a65396a07cba240e62fd3/aa440/Morne_he_Dark_Between_the_void_between_realms_vantablack_planet_a58cae09-c2ef-4fa9-acb6-f5d7f8394b30.png 1500w", "/static/5b01add7495a65396a07cba240e62fd3/71c1d/Morne_he_Dark_Between_the_void_between_realms_vantablack_planet_a58cae09-c2ef-4fa9-acb6-f5d7f8394b30.png 1536w"],
            "width": "700px",
            "className": "center horizontal"
          }}></img>{`
                  `}<div parentName="div" {...{
            "className": "caption"
          }}></div>{`
                `}</div>{`
                `}</undefined></p>
    <p><em parentName="p">{`The Dark Between`}</em>{` is a sort of “Steampunk”, sort of “Gaslamp” fantasy setting that takes place on the planet of `}<a parentName="p" {...{
        "href": "/Naos",
        "title": "Naos"
      }}>{`Naos`}</a>{`. The heart of `}<em parentName="p">{`The Dark Between`}</em>{` lies in the exploration of themes of gray morality, authority versus autonomy, chaos and order, and the concept that the more we know about the Universe, the more we don't.`}</p>
    <p>{`This setting has everything from unknowable elemental beings, powerful spellcasters, to genius inventors. `}<strong parentName="p">{`Naos`}</strong>{` is a vast world, with many varying cultures and points of view—the technological wonders of `}<a parentName="p" {...{
        "href": "/Aljieudum",
        "title": "Aljieudum"
      }}>{`Aljieudum`}</a>{`, the stretching verdant hills of `}<a parentName="p" {...{
        "href": "/Savaar",
        "title": "Savaar"
      }}>{`Savaar`}</a>{`, and the militaristic might of `}<a parentName="p" {...{
        "href": "/Tharsis",
        "title": "Tharsis"
      }}>{`Tharsis`}</a>{`.`}</p>
    <h2>{`Magic in Naos`}</h2>
    <p>{`In Naos, the primary source of Magic is `}<a parentName="p" {...{
        "href": "/The%20Luminary",
        "title": "The Luminary"
      }}>{`The Luminary`}</a>{`, contained within a great celestial moon that orbits the planet. All living creatures on Naos are connected to the Luminary through an unseen, metaphysical strand known as a `}<a parentName="p" {...{
        "href": "/Thread",
        "title": "Thread"
      }}>{`Thread`}</a>{`. Magic on Naos is more specifically known as `}<a parentName="p" {...{
        "href": "/Luminous%20Magic",
        "title": "Luminous Magic"
      }}>{`Luminous Magic`}</a>{`.`}</p>
    <p><undefined parentName="p">{`
                `}<div {...{
          "className": "right center horizontal"
        }}>{`
                  `}<img parentName="div" {...{
            "src": "/static/44315b09afad8f46d65e87a52525fcb6/aa440/Morne_a_young_mage_and_ethereal_rope_made_of_light_coming_from__230452e4-1d1e-4525-a060-724e16d1d24a.png",
            "srcSet": ["/static/44315b09afad8f46d65e87a52525fcb6/5ff7e/Morne_a_young_mage_and_ethereal_rope_made_of_light_coming_from__230452e4-1d1e-4525-a060-724e16d1d24a.png 375w", "/static/44315b09afad8f46d65e87a52525fcb6/1d69c/Morne_a_young_mage_and_ethereal_rope_made_of_light_coming_from__230452e4-1d1e-4525-a060-724e16d1d24a.png 750w", "/static/44315b09afad8f46d65e87a52525fcb6/aa440/Morne_a_young_mage_and_ethereal_rope_made_of_light_coming_from__230452e4-1d1e-4525-a060-724e16d1d24a.png 1500w", "/static/44315b09afad8f46d65e87a52525fcb6/71c1d/Morne_a_young_mage_and_ethereal_rope_made_of_light_coming_from__230452e4-1d1e-4525-a060-724e16d1d24a.png 1536w"],
            "width": "400px",
            "className": "right center horizontal"
          }}></img>{`
                  `}<div parentName="div" {...{
            "className": "caption"
          }}>{`An Acronist and his Thread`}</div>{`
                `}</div>{`
                `}</undefined></p>
    <p>{`Manifesting magical effects with Luminous Magic involves interfacing directly with the Luminary using the Thread and providing the celestial entity with a `}<a parentName="p" {...{
        "href": "/Conviction",
        "title": "Conviction"
      }}>{`Conviction`}</a>{`.`}</p>
    <p>{``}<a parentName="p" {...{
        "href": "/Acronist",
        "title": "Acronist"
      }}>{`Acronists`}</a>{` are what most magic users on Naos are known as. Since Luminous Magic is based on `}<em parentName="p">{`belief`}</em>{`, not just that of the individual, but to a greater power extent the collective belief of all of Naos, `}<em parentName="p">{`religion`}</em>{` has played a significant role in how Luminous Magic, and more specifically, Acronists form successful convictions without incurring `}<a parentName="p" {...{
        "href": "/Backlash",
        "title": "Backlash"
      }}>{`Backlash`}</a>{` likely to injure themselves or, more likely, tear a hole in reality. In areas of the world such as `}<a parentName="p" {...{
        "href": "/Aljieudum",
        "title": "Aljieudum"
      }}>{`Aljieudum`}</a>{`, where `}<a parentName="p" {...{
        "href": "/The%20Argent",
        "title": "The Argent"
      }}>{`The Argent`}</a>{` is the most prevalent religion, Acronists garner much of their abilities from the `}<a parentName="p" {...{
        "href": "/Primal%20Spirits",
        "title": "Primal Spirits"
      }}>{`Primal Spirits`}</a>{`, gaining specific powers through `}<a parentName="p" {...{
        "href": "/Primal%20Aspects",
        "title": "Primal Aspects"
      }}>{`Primal Aspects`}</a>{` tied to each Spirit.`}</p>
    <h2>{`Human Variants`}</h2>
    <p>{`While humans make up the majority of the population on `}<a parentName="p" {...{
        "href": "/Naos",
        "title": "Naos"
      }}>{`Naos`}</a>{`, there are a number of once-humans and human hybrids living alongside their ascendants.`}</p>
    <ul>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/Deep%20Dwellers",
          "title": "Deep Dwellers"
        }}>{`Deep Dwellers`}</a>{``}</li>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/Shifter",
          "title": "Shifter"
        }}>{`Shifters`}</a>{``}</li>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/Elemental%20Scion",
          "title": "Elemental Scion"
        }}>{`Elemental Scions`}</a>{``}</li>
    </ul>
    <h2>{`The Dark Between Campaign`}</h2>
    <ul>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/Session%20Summaries",
          "title": "Session Summaries"
        }}>{`Session Summaries`}</a>{``}</li>
    </ul>
    <h2>{`Characters`}</h2>
    <h3>{`Main`}</h3>
    <ul>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/Lucian%20Cullpepper",
          "title": "Lucian Cullpepper"
        }}>{`Lucian Cullpepper`}</a>{``}</li>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/Sulazar%20Stillwater",
          "title": "Sulazar Stillwater"
        }}>{`Sulazar Stillwater`}</a>{``}</li>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/Eileen%20Quint",
          "title": "Eileen Quint"
        }}>{`Eileen Quint`}</a>{``}</li>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/Arlo%20Livingston",
          "title": "Arlo Livingston"
        }}>{`Arlo Livingston`}</a>{``}</li>
    </ul>
    <h3>{`Others`}</h3>
    <ul>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/Enzo%20Quint",
          "title": "Enzo Quint"
        }}>{`Enzo Quint`}</a>{``}</li>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/Jules%20Kaplan",
          "title": "Jules Kaplan"
        }}>{`Jules Kaplan`}</a>{``}</li>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/Faro%20Desjardins",
          "title": "Faro Desjardins"
        }}>{`Faro Desjardins`}</a>{``}</li>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/Rysha%20Tan%20Mei",
          "title": "Rysha Tan Mei"
        }}>{`Rysha Tan Mei`}</a>{``}</li>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/Shani%20Mirza",
          "title": "Shani Mirza"
        }}>{`Shani Mirza`}</a>{``}</li>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/Rikkart%20Lauten",
          "title": "Rikkart Lauten"
        }}>{`Rikkart Lauten`}</a>{``}</li>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/Kalia%20Samira",
          "title": "Kalia Samira"
        }}>{`Kalia Samira`}</a>{``}</li>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/Adam%20La%20Cour",
          "title": "Adam La Cour"
        }}>{`Adam La Cour`}</a>{``}</li>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/Corrin%20Fane",
          "title": "Corrin Fane"
        }}>{`Corrin Fane`}</a>{``}</li>
    </ul>
    <h2>{`Places`}</h2>
    <h3>{`Nations`}</h3>
    <ul>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/Aljieudum",
          "title": "Aljieudum"
        }}>{`Aljieudum`}</a>{``}</li>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/Tharsis",
          "title": "Tharsis"
        }}>{`Tharsis`}</a>{``}</li>
    </ul>
    <h3>{`Cities`}</h3>
    <ul>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/Meripol",
          "title": "Meripol"
        }}>{`Meripol`}</a>{``}</li>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/Mehnateruna",
          "title": "Mehnateruna"
        }}>{`Mehnateruna`}</a>{``}</li>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/Brava",
          "title": "Brava"
        }}>{`Brava`}</a>{``}</li>
    </ul>
    <h2>{`Religion`}</h2>
    <ul>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/The%20Ascension",
          "title": "The Ascension"
        }}>{`The Ascension`}</a>{``}</li>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/The%20Argent",
          "title": "The Argent"
        }}>{`The Argent`}</a>{``}
        <ul parentName="li">
          <li parentName="ul">{``}<a parentName="li" {...{
              "href": "/Protoxy",
              "title": "Protoxy"
            }}>{`Protoxy`}</a>{``}</li>
          <li parentName="ul">{``}<a parentName="li" {...{
              "href": "/Missary",
              "title": "Missary"
            }}>{`Missary`}</a>{``}</li>
        </ul>
      </li>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/Aadimaon",
          "title": "Aadimaon"
        }}>{`Aadimaon`}</a>{``}</li>
    </ul>
    <h2>{`Organizations`}</h2>
    <ul>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/The%20Basentia%20Omnia",
          "title": "The Basentia Omnia"
        }}>{`The Basentia Omnia`}</a>{``}</li>
      <li parentName="ul">{`The `}<a parentName="li" {...{
          "href": "/Saetorim%20Institute",
          "title": "Saetorim Institute"
        }}>{`Saetorim Institute`}</a>{``}</li>
      <li parentName="ul">{`The `}<a parentName="li" {...{
          "href": "/Vigil",
          "title": "Vigil"
        }}>{`Vigil`}</a>{``}</li>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/The%20Church%20of%20Aphothis",
          "title": "The Church of Aphothis"
        }}>{`The Church of Aphothis`}</a>{``}</li>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/The%20High%20Authority",
          "title": "The High Authority"
        }}>{`The High Authority`}</a>{``}</li>
    </ul>
    <h3>{`Magic Index`}</h3>
    <ul>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/Acronist",
          "title": "Acronist"
        }}>{`Acronist`}</a>{``}</li>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/Backlash",
          "title": "Backlash"
        }}>{`Backlash`}</a>{``}</li>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/Conviction",
          "title": "Conviction"
        }}>{`Conviction`}</a>{``}</li>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/Convergence",
          "title": "Convergence"
        }}>{`Convergence`}</a>{``}</li>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/Cornerstone",
          "title": "Cornerstone"
        }}>{`Cornerstone`}</a>{``}</li>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/Collective%20Conviction",
          "title": "Collective Conviction"
        }}>{`Collective Conviction`}</a>{``}</li>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/The%20Luminary",
          "title": "The Luminary"
        }}>{`The Luminary`}</a>{``}</li>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/Thread",
          "title": "Thread"
        }}>{`Thread`}</a>{``}</li>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/Fade",
          "title": "Fade"
        }}>{`Fade`}</a>{``}</li>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/Spirit%20Core",
          "title": "Spirit Core"
        }}>{`Spirit Core`}</a>{``}</li>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/Prismagates",
          "title": "Prismagates"
        }}>{`Prismagates`}</a>{``}</li>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/Primal%20Spirits",
          "title": "Primal Spirits"
        }}>{`Primal Spirits`}</a>{``}
        <ul parentName="li">
          <li parentName="ul">{``}<a parentName="li" {...{
              "href": "/Primal%20Aspects",
              "title": "Primal Aspects"
            }}>{`Primal Aspects`}</a>{``}</li>
          <li parentName="ul">{``}<a parentName="li" {...{
              "href": "/The%20Exchange",
              "title": "The Exchange"
            }}>{`The Exchange`}</a>{``}</li>
        </ul>
      </li>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/Luminous%20Magic",
          "title": "Luminous Magic"
        }}>{`Luminous Magic`}</a>{``}</li>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/Junction",
          "title": "Junction"
        }}>{`Junction`}</a>{``}</li>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/Edification",
          "title": "Edification"
        }}>{`Edification`}</a>{``}</li>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/Fixed%20Truth",
          "title": "Fixed Truth"
        }}>{`Fixed Truth`}</a>{``}</li>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/Elemental%20Scion",
          "title": "Elemental Scion"
        }}>{`Elemental Scion`}</a>{``}</li>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/Dreaming",
          "title": "Dreaming"
        }}>{`Dreaming`}</a>{``}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      